import React, { useState } from 'react';
import './ContactUs.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContactUSImage from '../assets/Contactus/17.jpeg';
import emailjs from 'emailjs-com';

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_916va5i', 'template_jxrehvf', e.target, 'Nq_F9GtZhA01SlhWb')
            .then((result) => {
                console.log('Message Sent:', result.text);
                alert('Your message has been sent successfully!');
            }, (error) => {
                console.log('Error:', error.text);
                alert('Failed to send your message, please try again later.');
            });

        e.target.reset();
    };

    return (
        <div className="contact-top-section">
            <Navbar />
            <div className="contact-image-container">
                <img src={ContactUSImage} alt="Beautiful garden view" className="contact-image" />
                <h1 className="contact-heading">FEEL FREE TO CONTACT US</h1>
            </div>
            <div className="contact-form-container">
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className="input-group">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Your Phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <textarea
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <div className="checkbox-container">
                        <input type="checkbox" name="terms" required />
                        <label>Accept Terms and Conditions</label>
                    </div>
                    <button type="submit" className="contact-submit-button">SEND YOUR MESSAGE NOW</button>
                </form>
            </div>
            <div className="map-section">
                <h2 className="map-heading">Our Location</h2>
                <iframe
                    title="Location Map"
                    src="https://maps.google.com/maps?q=6.443981,79.995911&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                ></iframe>
            </div>



            <Footer />
        </div>
    );
}

export default ContactUs;
