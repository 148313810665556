import React, { useState } from 'react';
import './RoomsList.css';
import { FaShower, FaExpand, FaChessBoard, FaInternetExplorer, FaClock, FaChair } from 'react-icons/fa';
import { MdIron } from "react-icons/md";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
// Example imports for images
import roomImage1 from '../assets/Rooms/1.jpg';
import roomImage2 from '../assets/Rooms/DSC_6143.JPG';
import roomImage3 from '../assets/Rooms/3.jpeg';
import roomImage4 from '../assets/Rooms/DSC_6163.JPG';
import roomImage5 from '../assets/Rooms/DSC_6133.JPG';
import roomImage6 from '../assets/Rooms/DSC_6140.JPG';
import roomImage7 from '../assets/Rooms/DSC_6206.JPG';
import roomImage8 from '../assets/Rooms/DSC_6131.JPG';
import roomImage9 from '../assets/Rooms/DSC_6125.JPG';
import roomImage10 from '../assets/Rooms/l1.jpg';
import roomImage11 from '../assets/Rooms/l2.jpg';
import roomImage12 from '../assets/Rooms/l3.jpg';
import roomImage13 from '../assets/Rooms/DSC_6164.JPG';
import roomImage14 from '../assets/Rooms/DSC_6121.JPG';
import roomImage15 from '../assets/Rooms/ld.jpg';
import roomImage16 from '../assets/Rooms/lt1.jpg';
import roomImage17 from '../assets/Rooms/lt2.jpg';
import roomImage18 from '../assets/Rooms/lt3.jpg';
import roomImage19 from '../assets/Rooms/DSC_6191.JPG';
import roomImage20 from '../assets/Rooms/DSC_6192.JPG';
import roomImage21 from '../assets/Rooms/l3.jpg';
import roomImage22 from '../assets/Rooms/DSC_6163.JPG';
import roomImage23 from '../assets/Rooms/l2.jpg';
import roomImage24 from '../assets/Rooms/DSC_6141.JPG';
import roomImage25 from '../assets/Rooms/1.jpg';
import roomImage26 from '../assets/Rooms/3.jpeg';
import roomImage27 from '../assets/Rooms/DSC_6192.JPG';

// Data for the room cards
const cardsData = [
    {
        image: roomImage1,
        title: 'Standard Single Room – Bed & Breakfast',
        price: '$50.00 PER NIGHT',
        link: '/rooms/standard-single-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage2,
        title: 'Standard Single Room – Half Board',
        price: '$55.00 PER NIGHT',
        link: '/rooms/standard-single-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage3,
        title: 'Standard Single Room – Full Board',
        price: '$60.00 PER NIGHT',
        link: '/rooms/standard-single-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage4,
        title: 'Standard Double Room – Bed & Breakfast',
        price: '$60.50 PER NIGHT',
        link: '/rooms/standard-double-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage5,
        title: 'Standard Double Room – Half Board',
        price: '$67.50 PER NIGHT',
        link: '/rooms/standard-double-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage6,
        title: 'Standard Double Room – Full Board',
        price: '$74.00 PER NIGHT',
        link: '/rooms/standard-double-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage7,
        title: 'Standard Triple Room – Bed & Breakfast',
        price: '$74.00 PER NIGHT',
        link: '/rooms/standard-triple-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage8,
        title: 'Standard Triple Room – Half Board',
        price: '$80.50 PER NIGHT',
        link: '/rooms/standard-triple-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage9,
        title: 'Standard Triple Room – Full Board',
        price: '$94.00 PER NIGHT',
        link: '/rooms/standard-triple-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage10,
        title: 'luxury Single Rooms – Bed & Breakfast',
        price: '$55.00 PER NIGHT',
        link: '/rooms/luxury-single-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage11,
        title: 'luxury Single Room – Half Board',
        price: '$60.00 PER NIGHT',
        link: '/rooms/luxury-single-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage12,
        title: 'luxury Single Room – Full Board',
        price: '$65.00 PER NIGHT',
        link: '/rooms/luxury-single-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage13,
        title: 'luxury Double Room – Bed & Breakfast',
        price: '$67.50 PER NIGHT',
        link: '/rooms/luxury-double-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage14,
        title: 'luxury Double Rooms – Half Board',
        price: '$80.50 PER NIGHT',
        link: '/rooms/luxury-double-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage15,
        title: 'luxury Double Rooms – Full Board',
        price: '$94.50 PER NIGHT',
        link: '/rooms/luxury-double-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage16,
        title: 'luxury Triple Rooms – Bed & Breakfas',
        price: '$80.50  PER NIGHT',
        link: '/rooms/luxury-triple-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage17,
        title: 'luxury Triple Rooms – Half Board',
        price: '$107.50 PER NIGHT',
        link: '/rooms/luxury-triple-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage18,
        title: 'luxury Triple Rooms – Full Board',
        price: '$121.00 PER NIGHT',
        link: '/rooms/luxury-triple-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage19,
        title: 'Apartment Triple Rooms – Full Board',
        price: '$134.50 PER NIGHT',
        link: '/rooms/apartment-triple-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage20,
        title: 'Apartment Triple Rooms – Half Board',
        price: '$114.50 PER NIGHT',
        link: '/rooms/apartment-triple-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage21,
        title: 'Apartment Triple Rooms – Bed & Breakfast',
        price: '$87.50 PER NIGHT',
        link: '/rooms/apartment-triple-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage22,
        title: 'Apartment Double Rooms – Full Board',
        price: '$107.50 PER NIGHT',
        link: '/rooms/apartment-double-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage23,
        title: 'Apartment Double Rooms – Half Board',
        price: '$94.00 PER NIGHT',
        link: '/rooms/apartment-double-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage24,
        title: 'Apartment Double Rooms – Bed & Breakfast',
        price: '$74.00 PER NIGHT',
        link: '/rooms/apartment-double-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage25,
        title: 'Apartment Single Rooms – Full Board',
        price: '$67.50 PER NIGHT',
        link: '/rooms/apartment-single-fb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage26,
        title: 'Apartment Single Rooms – Half Board',
        price: '$60.50 PER NIGHT',
        link: '/rooms/apartment-single-hb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },
    {
        image: roomImage27,
        title: 'Apartment Single Rooms – Bed & Breakfast',
        price: '$54.00 PER NIGHT',
        link: '/rooms/apartment-single-bb', // Link to the webpage
        icons: [
            { icon: <FaShower />, description: 'Shower' },
            { icon: <FaExpand />, description: 'Expand' },
            { icon: <FaChessBoard />, description: 'Chess' },
            { icon: <MdIron />, description: 'Iron' },
            { icon: <FaInternetExplorer />, description: 'Internet' },
            { icon: <CgSmartHomeRefrigerator />, description: 'Refrigerator' },
            { icon: <FaClock />, description: 'Clock' },
            { icon: <FaChair />, description: 'Chair' }
        ]
    },



];

function Card({ image, title, price, link, icons }) {
    return (
        <div className="card">
            <img src={image} alt={title} className="card-image" />
            <div className="card-content">
                <h3 className="card-title">{title}</h3>
                <p className="card-subtitle">{title}</p>
                <p className="card-price">{price}</p>
                <div className="card-icons">
                    {icons.map((icon, index) => (
                        <div className="icon-container" key={index} title={icon.description}>
                            {icon.icon}
                        </div>
                    ))}
                </div>
                <a href={link} className="card-link">More Details</a>
            </div>
        </div>
    );
}

function RoomsList() {
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 10;
    const totalPages = Math.ceil(cardsData.length / cardsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = cardsData.slice(indexOfFirstCard, indexOfLastCard);

    return (
        <div className="rooms-list">
            <div className="cards-section">
                {currentCards.map((card, index) => (
                    <Card key={index} {...card} />
                ))}
            </div>
            <div className="pagination">
                <button onClick={handlePreviousClick} disabled={currentPage === 1}>Previous</button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handleClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
                <button onClick={handleNextClick} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
}

export default RoomsList;